import "core-js/modules/es.array.push.js";
import { getDictItems } from '@/api/expense';
import { getStorage, removeStorage } from '@utils/storage';
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      payTypeList: []
    };
  },
  computed: {
    show() {
      if (getStorage('tenantId', 'localStorage') !== null) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getPayType();
  },
  methods: {
    // 获取支付类型
    getPayType() {
      getDictItems({
        dictCode: 'tenant_qrcodr_type'
      }).then(res => {
        let result = res.data;
        if (result.code === '10000') {
          this.payTypeList = result.data || [];
        }
      });
    },
    // 跳转身份验证
    goVerification({
      itemValue
    }) {
      if (getStorage('tenantId', 'localStorage') !== null) {
        this.$router.push({
          name: 'expenseInfo',
          query: {
            type: itemValue,
            tenantId: getStorage('tenantId', 'localStorage')
          }
        });
      } else {
        this.$router.push({
          name: 'expenseVerification',
          query: {
            type: itemValue
          }
        });
      }
    },
    clean() {
      this.$dialog.confirm({
        title: '提示',
        message: '请确认是否退出'
      }).then(() => {
        // on confirm
        removeStorage('tenantId', 'localStorage');
        this.show = false;
        this.$router.replace({
          name: 'expenseType'
        });
      }).catch(() => {
        // on cancel
      });
    }
  }
};