var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page"
  }, [_vm.show ? _c('span', {
    staticClass: "page-button",
    staticStyle: {
      "color": "#FFFFFF",
      "font-size": "16px"
    },
    on: {
      "click": _vm.clean
    }
  }, [_vm._v("退出")]) : _vm._e(), _vm._m(0), _c('img', {
    staticClass: "page-logo",
    attrs: {
      "src": require("../../assets/images/payExpence/logo.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content"
  }, _vm._l(_vm.payTypeList, function (item) {
    return _c('div', {
      key: item.itemValue,
      staticClass: "card mt-8 space-between",
      on: {
        "click": function ($event) {
          return _vm.goVerification(item);
        }
      }
    }, [_c('div', {
      staticClass: "card-content"
    }, [item.itemValue === 'WARTER_FEE' ? _c('van-image', {
      attrs: {
        "width": "44",
        "height": "44",
        "src": require('../../assets/images/payExpence/water.png')
      }
    }) : _vm._e(), item.itemValue === 'PREPOWER_FEE' ? _c('van-image', {
      attrs: {
        "width": "44",
        "height": "44",
        "src": require('../../assets/images/payExpence/dian.png')
      }
    }) : _vm._e(), item.itemValue === 'PARK_FEE' ? _c('van-image', {
      attrs: {
        "width": "44",
        "height": "44",
        "src": require('../../assets/images/payExpence/car.png')
      }
    }) : _vm._e(), _c('span', {
      staticClass: "card-text ml-12"
    }, [_vm._v(_vm._s(item.itemText))])], 1), _c('van-icon', {
      staticClass: "card-icon",
      attrs: {
        "name": "arrow",
        "size": "18"
      }
    })], 1);
  }), 0), _c('img', {
    staticClass: "page-title",
    attrs: {
      "src": require("../../assets/images/payExpence/title.png"),
      "alt": ""
    }
  })])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-img"
  }, [_c('img', {
    staticClass: "back-img",
    attrs: {
      "src": require("../../assets/images/payExpence/home.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };